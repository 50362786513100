interface RouteTitleMap {
  [pattern: string]: string;
}

const routeTitleMap: RouteTitleMap = {
  '^/eiq-connect/guests/\\d+/edit$': 'Edit Guest',
  '^/eiq-connect/guests/\\d+/view$': 'View Guest',
  '^/eiq-connect/guests/add$': 'Add Guest',
  '^/eiq-connect/guests/search$': 'Search Guests',
  '^/eiq-connect/guests/share$': 'Share Guest',
  '^/eiq-connect/guests/filter$': 'Filter Guests',
  '^/eiq-connect/guests$': 'Guests',
  '^/eiq-connect/vehicles/\\d+/edit$': 'Edit Vehicle',
  '^/eiq-connect/vehicles/\\d+/view$': 'View Vehicle',
  '^/eiq-connect/vehicles/add$': 'Add Vehicle',
  '^/eiq-connect/vehicles/search$': 'Search Vehicles',
  '^/eiq-connect/vehicles$': 'Vehicles',
  '^/eiq-connect/devices': 'Devices',
  '^/eiq-connect/devices/search$': 'Search Devices',
  '^/eiq-connect/pets/\\d+/edit$': 'Edit Pet',
  '^/eiq-connect/pets/\\d+/view$': 'View Pet',
  '^/eiq-connect/pets/add$': 'Add Pet',
  '^/eiq-connect/pets/search$': 'Search Pets',
  '^/eiq-connect/pets$': 'Pets',
  '^/eiq-connect/traffic/[\\w-]+/view$': 'View Traffic',
  '^/eiq-connect/traffic/search$': 'Search Traffic',
  '^/eiq-connect/traffic$': 'Traffic',
  '^/eiq-connect/citations$': 'Citations',
  '^/eiq-connect/citations/\\d+/view$': 'View Citation',
  '^/eiq-connect/citations/filter$': 'Filter Citations',
  '^/eiq-connect/citations/search$': 'Search Citations',
  '^/eiq-connect/account/edit$': 'Edit Account',
  '^/eiq-connect/account$': 'Account',
};

export const getPageTitle = (pathname: string) => {
  if (pathname === '/eiq-connect') {
    return null;
  }

  for (const pattern in routeTitleMap) {
    if (new RegExp(pattern).test(pathname)) {
      return routeTitleMap[pattern];
    }
  }
  return pathname;
};
