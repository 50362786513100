import CommunityResolverClient from 'common/utils/http/community-resolver-http-client';
import { ICommunity, IResolveCommunityRequest } from './community-resolver.types';
import { ServerClient } from 'common/utils/http/http-clients';
import { residentNotFoundErrorMessage } from '../../constans/alert-messages.constants';
import { getItem, removeItem, setItem } from 'common/helpers/authorization-store.helper';

class CommunityResolverService {
  /**
   * Resolve the community API URL from the login. It makes a call to the community resolver API to get the community API URL.
   * Then it stores the URL in the local storage and use this base URL to send all further requests
   */
  async resolve(login: string): Promise<string> {
    if (process.env.REACT_APP_SERVER_BASE_URL?.length) {
      this.setCurrentCommunityUrl(process.env.REACT_APP_SERVER_BASE_URL || '');
      ServerClient.defaults.baseURL = process.env.REACT_APP_SERVER_BASE_URL;
      return process.env.REACT_APP_SERVER_BASE_URL || '';
    }

    const request: IResolveCommunityRequest = { login: login };
    const res = await CommunityResolverClient.post<Array<ICommunity>>(`/resident/resolve-community`, request);

    if (res.data.length === 0) {
      throwError(residentNotFoundErrorMessage);
    }

    if (res.data.length > 1) {
      // TODO Need to handle in the future
      throwError('The resident has more than one community.');
    }

    function throwError(message: string) {
      throw {
        response: {
          status: 404,
          data: {
            message,
          },
        },
      };
    }

    const url = res.data[0].communityApiUri;
    this.setCurrentCommunityUrl(url);

    ServerClient.defaults.baseURL = url;
    return url;
  }

  async resolveByCommunityId(communityId: string): Promise<ICommunity> {
    const res = await CommunityResolverClient.get<ICommunity>(`community/${communityId}`);

    if (!res?.data) {
      throw new Error('Community not found');
    }

    return res.data;
  }

  setCurrentCommunityUrl(url: string) {
    setItem('SERVER_BASE_URL', url);
  }

  getCurrentCommunityUrl(): string {
    return getItem('SERVER_BASE_URL') || '';
  }

  reset() {
    removeItem('SERVER_BASE_URL');
  }
}

export const communityResolverService = new CommunityResolverService();
export default communityResolverService;
