import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { Button, Tag } from 'antd';
import InfoField from '../../../../common/components/info-block/info-field/info-field';
import { ReactComponent as EditIcon } from 'assets/icons/eiq-connect/edit.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GotToDetailsIcon } from 'assets/icons/eiq-connect/got-to-details.svg';
import { ReactComponent as QRCodeIcon } from 'assets/icons/eiq-connect/qr-code.svg';
import BaseCellText from '../../../../common/components/table/base-cell-text';
import { IGuestRead } from '../../../../common/services/residents-service/residents.service.types';
import { getDateRangePeriod } from '../../../../common/helpers/date.helper';
import { useTableContext } from '../../../../common/components/table/table-context';
import { getGuestTypeTag } from '../../../../common/components/expired-guest-tag/expired-guest-tag';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import Feature from 'common/components/feature/feature';
import { FeatureName } from 'common/enums/feature-name.enum';
import guestService from 'common/services/guest-service/guest.service';
import { GuestDateCheck } from 'common/services/guest-service/guest.service.types';

export interface IEIQConnectRead extends IGuestRead {
  address: string;
}

export const webGuestsListConfig = [
  {
    key: 'fullName',
    title: 'Name',
    dataKey: 'fullName',
    width: 195,
    minWidth: 150,
    flexGrow: 1,
    getValue: (rowData: IEIQConnectRead) => {
      return isServiceGuest(rowData.type) ? `${rowData.companyName}` : `${rowData.firstName} ${rowData.lastName}`;
    },
    cellRenderer: ({ rowData }: ICellRendererProps<IEIQConnectRead>) => {
      if (!rowData) {
        return <div></div>;
      }
      return (
        <div className="eiq-column">
          <div className="eiq-column__title">
            {rowData.issuedKey && <KeyIcon />}
            {isServiceGuest(rowData.type) ? `${rowData.companyName}` : `${rowData.firstName} ${rowData.lastName}`}
          </div>
          <div className="eiq-column__text">{rowData.address}</div>
          <div className="eiq-column-row">
            {getGuestTypeTag(rowData)}
            {rowData.isShared ? <Tag className="eiq-tag expired">Shared</Tag> : null}
          </div>
        </div>
      );
    },
  },
  {
    key: 'daysOfWeek',
    title: 'DaysOfWeek',
    dataKey: 'daysOfWeek',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    getValue: (rowData: IEIQConnectRead) => rowData.daysOfWeek?.join(', '),
    cellRenderer: (cell: ICellRendererProps<IEIQConnectRead>) => {
      const rowData = cell?.rowData;
      if (rowData.validity === 'Permanent') {
        return (
          <BaseCellText>
            {
              <InfoField
                label="Days allowed to visit"
                value={rowData?.daysOfWeek?.length >= 7 ? `All days` : rowData?.daysOfWeek?.join(', ')}
              />
            }
          </BaseCellText>
        );
      }
    },
  },
  {
    key: 'validity',
    title: 'Validity',
    dataKey: 'validity',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    getValue: (rowData: IEIQConnectRead) => {
      if (rowData.validity === 'DateRange') {
        return { startDate: rowData.startDate, endDate: rowData.endDate };
      } else if (rowData.validity !== 'Permanent') {
        return { startDate: rowData.visitDate, endDate: rowData.visitDate };
      }
    },
    cellRenderer: ({ rowData }: ICellRendererProps<IEIQConnectRead>) => (
      <BaseCellText>{<InfoField value={getDateRangePeriod(rowData)} label="Validity" />}</BaseCellText>
    ),
  },
  {
    key: 'action',
    title: 'Actions',
    width: 100,
    minWidth: 100,
    cellRenderer: ({ rowData }: ICellRendererProps<IEIQConnectRead>) => {
      const expairedCheckData: GuestDateCheck = {
        validity: rowData.validity,
        visitDate: rowData.visitDate,
        endDate: rowData.endDate,
      };
      const isGuestExpired = guestService.isExpired(expairedCheckData);
      const CustomComponent = () => {
        const navigate = useNavigate();
        const tableContext = useTableContext();

        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Feature name={FeatureName.FastAccess}>
              <Button
                size="large"
                className={`round-primary ${isGuestExpired ? 'disabled-button' : ''}`}
                disabled={isGuestExpired}
                icon={<QRCodeIcon />}
                onClick={() => {
                  tableContext.setSelectedRow(rowData);
                  navigate(`/eiq-connect/guests/${rowData.id}/view`);
                }}
              />
            </Feature>
            <Button
              size="large"
              className="round-primary"
              icon={<EditIcon />}
              onClick={() => {
                tableContext.setSelectedRow(rowData);
                navigate(`/eiq-connect/guests/${rowData.id}/edit`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
  {
    key: 'arrow',
    title: 'arrow',
    hidden: true,
    width: 15,
    minWidth: 15,
    cellRenderer: ({ rowData }: ICellRendererProps<IEIQConnectRead>) => {
      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <GotToDetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/guests/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
  {
    key: 'identity',
    dataKey: 'identity',
    title: 'id',
    hidden: true,
    width: 0,
    minWidth: 0,
    sortable: true,
    getValue: (rowData: IEIQConnectRead) => rowData.id,
  },
];
