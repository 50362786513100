import { makeAutoObservable } from 'mobx';
import React from 'react';
import { FAST_ACCESS_PASSES } from './fast-access-store.constants';
import fastAccessService from 'common/services/fast-access-service/fast-access-service';
import userAuthStore from '../user-auth/user-auth.store';
import { IResidentFastAccessPassRead, ICreateResidentFastAccessPass } from 'common/services/fast-access-service/fast-access-service.types';
import { DATE_TIME_YYYY_MM_DD_HH_mm_ss_SSSZ } from 'common/constans/date.constants';
import dayjs from 'dayjs';
import { getItem, removeItem, setItem } from 'common/helpers/authorization-store.helper';

class ResidentFastAccessPassStore {
  fastAccessPasses: IResidentFastAccessPassRead[] = [];

  constructor() {
    makeAutoObservable(this);
    this.restore();
  }

  set(fastAccessPasses: IResidentFastAccessPassRead[]) {
    setItem(FAST_ACCESS_PASSES, JSON.stringify(fastAccessPasses));
    this.fastAccessPasses = fastAccessPasses;
  }

  restore() {
    const source = getItem(FAST_ACCESS_PASSES);
    this.fastAccessPasses = source ? JSON.parse(source) : [];
  }

  clear() {
    removeItem(FAST_ACCESS_PASSES);
    this.fastAccessPasses = [];
  }

  async getFastAccessPass(addressId: number, vehicleId: number | null, forceRefresh: boolean = false) {
    const addressFastAccessPass = this.fastAccessPasses.find((i) => i.addressId === addressId && i.vehicleId === vehicleId);

    const expirationDate = dayjs(addressFastAccessPass?.expires);
    const today = dayjs().utc();
    const isExpired = expirationDate.isBefore(today);

    if (addressFastAccessPass && !isExpired && !forceRefresh) {
      return addressFastAccessPass;
    }

    const data: ICreateResidentFastAccessPass = {
      addressId: addressId,
      residentId: userAuthStore.webGuestResidentId!,
      validFrom: dayjs().startOf('date').format(DATE_TIME_YYYY_MM_DD_HH_mm_ss_SSSZ),
      expires: dayjs().startOf('date').add(3, 'day').format(DATE_TIME_YYYY_MM_DD_HH_mm_ss_SSSZ),
      vehicleId: vehicleId,
    };

    const result = await fastAccessService.createResidentFastAccessPass(data);

    if (addressFastAccessPass) {
      const index = this.fastAccessPasses.findIndex((i) => i.addressId === addressId);
      this.fastAccessPasses[index] = result;
    } else {
      this.fastAccessPasses.push(result);
    }

    this.set(this.fastAccessPasses);

    return result;
  }
}

const fastAccessPassStore = new ResidentFastAccessPassStore();
export const FastAccessStoreContext = React.createContext<ResidentFastAccessPassStore>(fastAccessPassStore);
export default fastAccessPassStore;
