import { Button, Modal, Popover } from 'antd';
import './voice-form.scss';
import React, { useRef, useState } from 'react';
import { ReactComponent as MicrophoneIcon } from 'assets/icons/eiq-connect/microphone.svg';
import { ReactComponent as GuestIcon } from 'assets/icons/eiq-connect/guest-by-voice.svg';
import { ReactComponent as AddGuestIcon } from 'assets/icons/eiq-connect/logout-white.svg';
import InfoField from '../../../../../../common/components/info-block/info-field/info-field';
import speechService from '../../../../../../common/services/speech-service/speech.service';
import { textToGuestForm } from '../../../../../../common/components/speech-to-text/speech-to-text.hepler';
import { observer } from 'mobx-react-lite';
import { IGuestVoiceForm } from './voice-form.type';
import eiqConnectStore from '../../../../store/eiq-connect.store';
import { useNavigate } from 'react-router-dom';
import { notification } from '../../../../../../common/utils/notification';

const init = 'init';
const listen = 'listen';

// console.log('debug', textToGuestForm("Yuri's delivery Warner Brothers Date range:"));
// console.log('debug', textToGuestForm('Donald Duck Delivery Warner Brothers Bait range:'));
const timeoutMinutes = 3;
const TIME_TO_STOP = timeoutMinutes * 60 * 1000;

export const VoiceForm = observer(() => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [isStartRecognation, setStartRecognation] = useState(false);
  const [isListen, setListen] = useState<typeof init | typeof listen | null>();
  const [textDuringRecognazing, setTextDuringRecognazing] = useState<string | null>();
  const autoStopId = useRef<any>();
  const cancelNextResult = useRef(false);
  const [gestForm, setGuestForm] = useState<IGuestVoiceForm | null>(null);

  const handleClose = () => {
    setOpen(false);
    setStartRecognation(false);
  };

  const handleAddGuest = () => {
    eiqConnectStore.setGuestVoiceFrom(gestForm);
    navigate('/eiq-connect/guests/add');
  };

  const startRecognition = async () => {
    await speechService.updateToken();

    speechService.setGrammarPhrases([
      'delivery',
      'family',
      'guest',
      'service',
      'urgent',
      'ride',
      'ride share',
      'health',
      'medical',
      'health and medical',
      'guest name',
      'visitor name',
      'name',
      'guest type',
      'visitor type',
      'type',
      'company',
      'validity',
      'one time',
      'one-time',
      'permanent',
      'today',
      'tomorrow',
      'date',
      'date range',
      'selected date range',
      'license plate number',
      'plate',
      'number',
      'state',
      'license state',
    ]);

    setListen(init);
    speechService.setOnRecognizing((text: string) => {
      // setTextDuringRecognazing(text);
    });

    await speechService
      .startRecognizing((fullText) => {
        if (cancelNextResult.current) {
          cancelNextResult.current = false;
          return;
        }
        console.log('fullText', fullText);
        const result = textToGuestForm(fullText);
        // TODO Add an error if there is no value, to notify a user
        console.log('fullText parse', result);
        setGuestForm((prev) => ({
          ...prev,
          ...result,
        }));
      })
      .then(() => {
        setListen(listen);

        autoStopId.current = setTimeout(() => {
          stopRecognition();
          notification.error({ message: 'Sorry, the recording timed out. Please try again.' });
        }, TIME_TO_STOP);
      })
      .catch(() => {
        setListen(null);
      });
  };

  const stopRecognition = async () => {
    clearTimeout(autoStopId.current);
    speechService.stopRecognizing();
    setListen(null);
    setTextDuringRecognazing(null);
  };

  const helpContent = (
    <>
      <h2 className="voice-title">Add a guest by voice</h2>
      <span className="voice-caption">Please state who you want to add in one sentence:</span>
      <ul className="voice-list">
        <li>Full name</li>
        <li>Type (Guest, Service, Delivery, Ride share, Family, Employee, Health and Medical)</li>
        <li>Company</li>
        <li>Validity (One-time, Today, Tomorrow, Permanent, Selected date range)</li>
        <li>License plate number</li>
        <li>License state</li>
        <li>
          You can use the field name to set or replace the value of a field. For example, "Type Guest, Validity Date Range" will only change
          those two fields.
        </li>
      </ul>
      <div className="voice-footer">
        <span className="voice-status">'Tap the mic to talk.'</span>
      </div>
      <Button
        type="primary"
        className="voice-button"
        onClick={() => {
          setStartRecognation(true);
        }}
        icon={<MicrophoneIcon />}
      />
    </>
  );

  const mainContent = (
    <>
      <h2 className="voice-title">Add a guest by voice</h2>
      <div className="voice-form">
        <InfoField label="Name" value={gestForm?.name && gestForm?.name.length > 0 ? gestForm?.name : '-'} />
        <InfoField label="Type" value={gestForm?.type && gestForm?.type?.length > 0 ? guestTypeMap[gestForm?.type] : '-'} />
        <InfoField label="Company" value={gestForm?.companyName && gestForm?.companyName?.length > 0 ? gestForm?.companyName : '-'} />
        <InfoField label="Validity" value={gestForm?.validity && gestForm?.validity?.length > 0 ? validityMap[gestForm?.validity] : '-'} />
        <InfoField label="License number" value={gestForm?.carNumber && gestForm?.carNumber.length > 0 ? gestForm?.carNumber : '-'} />
        <InfoField label="License state" value={gestForm?.carState && gestForm?.carState.length > 0 ? gestForm?.carState : '-'} />
      </div>
      <div className="voice-footer">
        <span className={`voice-status ${isListen === init ? 'voice-alert' : ''}`}>
          {!gestForm && !isListen && 'Tap the mic to talk.'}
          {isListen === init && 'Set up the microphone.'}
          {isListen === listen && 'Listening...'}
        </span>
        {isListen === init ||
          (isListen === listen && (
            <div>
              <Button
                type="link"
                size={'small'}
                className="voice-cancel"
                onClick={() => {
                  cancelNextResult.current = true;
                  stopRecognition();
                }}>
                Cancel
              </Button>
            </div>
          ))}
        {gestForm && !isListen && (
          <Button type="primary" htmlType="submit" icon={<AddGuestIcon />} className="eiq-button" onClick={handleAddGuest}>
            Add guest
          </Button>
        )}
      </div>
      <Button
        type="primary"
        className="voice-button"
        onClick={() => {
          if (isListen !== listen) {
            startRecognition();
          } else {
            stopRecognition();
          }
        }}
        icon={<MicrophoneIcon />}
      />
    </>
  );

  return (
    <div className="App">
      <div className="button-container">
        <Button
          type="primary"
          className="voice-button voice-mobile-btn"
          onClick={() => {
            setOpen(true);
          }}
          icon={<GuestIcon />}
        />

        {isOpen && (
          <div className="voice-modal">
            <div className="overlay" onClick={() => handleClose()}></div>
            <div className="voice-content">
              {!isStartRecognation && helpContent}
              {isStartRecognation && mainContent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const validityMap: Record<string, string> = {
  OneTime: 'One time',
  Permanent: 'Permanent',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
  DateRange: 'Selected date range',
};

const guestTypeMap: Record<string, string> = {
  Delivery: 'Delivery',
  Family: 'Family',
  Guest: 'Guest',
  Service: 'Service',
  RideShare: 'Ride share',
  Employee: 'Employee',
  Medical: 'Health & Medical',
};
