import './eiq-connect-fast-access-pass.scss';
import React, { useState, useEffect } from 'react';
import { Col, Divider, Row, QRCode } from 'antd';
import LogoIcon from 'assets/icons/eiq-connect/eiq-connect-logo-with-trademark.png';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';
import fastAccessService from 'common/services/fast-access-service/fast-access-service';
import { useParams } from 'react-router-dom';
import { IFastAccessPassDetails } from 'common/services/fast-access-service/fast-access-service.types';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { formatCustomDateTime } from 'common/helpers/date.helper';
import { formatEiqPassportCode } from 'common/helpers/eiq-passport.helper';
import AddToWalletBtn from './add-to-wallet-btn/add-to-wallet-btn';

const FastAccessPass = () => {
  const [fastAccessPass, setFastAccessPass] = useState<IFastAccessPassDetails | null>(null);
  const { communityId, fastAccessPassId } = useParams();

  const isIPhoneOrAndroid = () => {
    const userAgent = navigator.userAgent;
    return /IPhone/i.test(userAgent);
  };

  useEffect(() => {
    if (!communityId || !fastAccessPassId) {
      return;
    }

    communityResolverService.resolveByCommunityId(communityId).then((community) => {
      fastAccessService.getFastAccessPassDetails(fastAccessPassId, community.communityApiUri).then((fastAccessPass) => {
        setFastAccessPass(fastAccessPass);
      });
    });
  }, []);

  const handleAddToWallet = async () => {
    if (!communityId || !fastAccessPassId) {
      return;
    }
    var community = await communityResolverService.resolveByCommunityId(communityId);
    var file = await fastAccessService.getFastAccessForPassAppleWallet(fastAccessPassId, community.communityApiUri);

    const downloadUrl = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Add-to-Wallet.pkpass';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="fast-access-pass">
        <div className="logo-wrapper">
          <img className="logo" src={LogoIcon} alt="logo" />
        </div>
        <Row className={'row'}>
          <Col className="col">
            <div className="guest-name">Hello {fastAccessPass?.name},</div>
            <div className="guest-text">Present this QR code and your photo ID at the gate.</div>
            <div className="fast-access-wrapper">
              <div className="fast-access-qr-code-border">
                {fastAccessPass?.id && <QRCode size={200} value={formatEiqPassportCode(fastAccessPass.id)} bordered={false} />}
              </div>
            </div>
          </Col>
        </Row>
        <div className="community">
          <div className="title">Community</div>
          <div className="text">{fastAccessPass?.community}</div>
        </div>
        <Divider className="auth-diver" />
        <div className="from">
          <div className="title">From</div>
          <div className="text">{fastAccessPass?.residentName}</div>
        </div>
        <Divider className="auth-diver" />
        <div className="title">Address</div>
        <div className="text">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://maps.google.com/?q=${fastAccessPass?.state}, ${fastAccessPass?.city}, ${formatAddressLabel(
              fastAccessPass?.address,
            )}`}>
            {formatAddressLabel(fastAccessPass?.address)}
          </a>
        </div>
        <Divider className="auth-diver" />
        <div className="title">Valid</div>
        <div className="text">
          {formatCustomDateTime(fastAccessPass?.validFrom)} -{' '}
          {fastAccessPass?.expires ? formatCustomDateTime(fastAccessPass?.expires) : 'Permanent'}
        </div>
        <Divider className="auth-diver" />
        <div className="title">Community Rules</div>
        <div className="text">{fastAccessPass?.communityRules ? fastAccessPass?.communityRules : 'None'}</div>
        <Divider className="auth-diver" />
        {isIPhoneOrAndroid() ? <AddToWalletBtn onClick={() => handleAddToWallet().then()} /> : null}
      </div>
    </>
  );
};

export default FastAccessPass;
