import '../notifications.scss';
import { Button, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import TableBase from 'common/components/table/table-base';
import { notificaitonService } from 'common/services/notification-service/notification.service';
import { notificationTableConfig } from '../notifications.config';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { INotification } from 'common/services/notification-service/notification.service.types';
import { ReactComponent as EmailReadIcon } from 'assets/icons/eiq-connect/mail-read.svg';
import { ReactComponent as EmailUnreadIcon } from 'assets/icons/eiq-connect/mail-unread.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/eiq-connect/trash.svg';
import NotificationDetailsModal from '../notification-details-modal/notification-details-modal';
import BottomHeader from '../../../components/bottom-header/bottom-header';

const defaultPagination = {
  limit: 6,
  offset: 0,
};

export const NotificationList = ({ isRead = null, className, filters }: any) => {
  const { isMobile } = useMedia();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<INotification | null>(null);

  const tableControls = useTableControls({
    onSelectedRows: (notifications: INotification[]) => {
      setSelectedIds(notifications.map((n) => n.id));
    },
    onSelectedRow: (row: any) => {
      setSelectedNotification(row);
    },
    onFetch: notificaitonService.getAll,
    initialColumns: notificationTableConfig,
    pagination: !isMobile ? defaultPagination : null,
  });

  useEffect(() => {
    onChangeIsReadFilters(isRead);
  }, []);

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        avatar: false,
        avatarMobile: true,
        title: true,
        createdAt: true,
        isRead: false,
        action: false,
      });
    } else {
      tableControls.hideColumns({
        avatar: true,
        avatarMobile: false,
        title: true,
        createdAt: true,
        isRead: true,
        action: true,
      });
    }
  }, [isMobile]);

  const onChangeIsReadFilters = (value: any) => {
    if (value === undefined) {
      return;
    }

    tableControls.updateFilter('isRead', [{ operator: FilterTypesEnum.Equals, value }], 'AND');
  };

  const markNotifications = async (residentNotificationIds: number[], isRead: boolean) => {
    await notificaitonService.markNotifications({ residentNotificationIds, isRead });
    tableControls.refetchData();
    notificaitonService.getPreviewNotificationsData(6);
    tableControls.setSelectedRows([]);
  };

  const deleteNotifications = async (residentNotificationIds: number[]) => {
    await notificaitonService.deleteNotifications(residentNotificationIds);
    tableControls.refetchData();
    notificaitonService.getPreviewNotificationsData(6);
  };

  const onCloseDetails = async () => {
    const { id, isRead } = selectedNotification!;
    setSelectedNotification(null);
    !isRead && (await markNotifications([id], true));
  };

  const filtersPanel = (
    <Space size={10}>
      {selectedIds.length === 0 && (
        <Select
          className="notifications-filter"
          style={{ width: 100 }}
          defaultValue={isRead}
          value={filters?.isRead}
          onChange={onChangeIsReadFilters}
          options={[
            { label: 'All', value: null },
            { label: 'Read', value: true },
            { label: 'Unread', value: false },
          ]}
        />
      )}
      {selectedIds.length > 0 && (
        <>
          <Button
            size="large"
            className="round-primary"
            onClick={async () => await markNotifications(selectedIds, false)}
            icon={<EmailUnreadIcon />}
          />
          <Button
            size="large"
            className="round-primary"
            onClick={async () => await markNotifications(selectedIds, true)}
            icon={<EmailReadIcon />}
          />
          <Button
            size="large"
            className="round-primary"
            onClick={async () => await deleteNotifications(selectedIds)}
            icon={<TrashIcon />}
          />
        </>
      )}
    </Space>
  );

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <div className={`eiq-notifications-page ${className ?? ''}`}>
          <div className={`eiq-header main-page`}>
            <BottomHeader title="Notifications" showBackText={false} />
            {filtersPanel}
          </div>

          <div className="eiq-content">
            <TableBase selectableRows={true} wrapperClass="eiq-table" headerHeight={0} fixed={false} rowHeight={90} />
          </div>
        </div>
      </TableContextProvider>
      {selectedNotification && (
        <NotificationDetailsModal notification={selectedNotification} isOpened={!!selectedNotification} onClose={onCloseDetails} />
      )}
    </>
  );
};
