import React from 'react';
import { ReactComponent as Expired } from 'assets/icons/eiq-connect/expired.svg';
import { Tag } from 'antd';
import { camelCaseToRegularForm } from '../../helpers/camel-case-to-regular-form.helper';
import { ServerGuestValidity } from '../../enums/guest-validity.enum';
import { StrToDate, StrToDateTimeLocal } from '../../helpers/date.helper';
import { GuestType } from '../../enums/guest-type.enum';
import { IGuestRead } from '../../services/residents-service/residents.service.types';
import dayjs from 'dayjs';
import { getGuestTypeValue } from 'common/helpers/guest-type.helpers';

export const guestTypeColorMap: Record<GuestType, string> = {
  [GuestType.Undefined]: '#DCDCDC',
  [GuestType.Family]: '#C1FFEA',
  [GuestType.Service]: '#D1E3FF',
  [GuestType.RideShare]: '#FFD889',
  [GuestType.Delivery]: '#FFD889',
  [GuestType.Guest]: '#EECAFF',
  [GuestType.UrgentService]: '#D1E3FF',
  [GuestType.Employee]: '#ffd1ee',
  [GuestType.Medical]: '#D1E3FF',
};

export const getGuestTypeTag = (rowData: IGuestRead) => {
  const { type, validity, endDate, visitDate } = rowData;
  const currentDate = dayjs();

  const expiredGuestTag = (
    <Tag className="eiq-tag expired" icon={<Expired />}>
      {getGuestTypeValue(type)}
    </Tag>
  );

  if (validity === ServerGuestValidity.DateRange && currentDate.isAfter(StrToDate(endDate), 'day')) {
    return expiredGuestTag;
  } else if (validity === ServerGuestValidity.OneTime && currentDate.isAfter(StrToDateTimeLocal(visitDate), 'day')) {
    return expiredGuestTag;
  } else {
    return (
      <Tag className="eiq-tag" color={guestTypeColorMap[type as GuestType]} style={{ color: '#292B2E' }}>
        {getGuestTypeValue(type)}
      </Tag>
    );
  }
};
