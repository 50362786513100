import { IRadioGroupProps } from 'common/components/form-elements/radio-group/radio-group.types';
import { SortBy } from 'common/components/table/table.types';

export const guestSortKeys = {
  earliestAdditionKey: 0,
  latestAdditionKey: 1,
  alphabeticallyKey: 2,
};

export const GuestSortOptions: IRadioGroupProps = {
  options: [
    {
      label: 'Latest addition',
      value: guestSortKeys.latestAdditionKey,
    },
    {
      label: 'Earliest Addition',
      value: guestSortKeys.earliestAdditionKey,
    },
    {
      label: 'Alphabetically',
      value: guestSortKeys.alphabeticallyKey,
    },
  ],
};

export const Sorters: SortBy[] = [
  {
    key: 'identity',
    order: 'asc',
    column: {
      key: 'identity',
      sortable: true,
      width: 0,
    },
  },
  {
    key: 'identity',
    order: 'desc',
    column: {
      key: 'identity',
      sortable: true,
      width: 0,
    },
  },
  {
    key: 'fullName',
    order: 'asc',
    column: {
      key: 'fullName',
      sortable: true,
      width: 0,
    },
  },
];
