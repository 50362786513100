import { ServerClient } from 'common/utils/http/http-clients';
import {
  IResidentFastAccessPassRead,
  IGuestFastAccessPassRead,
  IFastAccessPassDetails,
  ICreateResidentFastAccessPass,
  ISendGuestFastAccessPass,
  ICreateGuestFastAccessPass,
} from './fast-access-service.types';

class FastAccessService {
  async createResidentFastAccessPass(data: ICreateResidentFastAccessPass): Promise<IResidentFastAccessPassRead> {
    const res = await ServerClient.post<IResidentFastAccessPassRead>('/eiq-passport/resident', data);
    return res?.data;
  }

  async createGuestFastAccessPass(data: ICreateGuestFastAccessPass): Promise<IGuestFastAccessPassRead> {
    const res = await ServerClient.post<IGuestFastAccessPassRead>('/eiq-passport/guest', data);
    res.data.url = res.headers['location'];

    return res.data;
  }

  async sendGuestFastAccessPass(id: string, model: ISendGuestFastAccessPass): Promise<void> {
    const data = new FormData();
    data.append('file', model.file);
    data.append('contact', model.contact);
    await ServerClient.post<void>(`/eiq-passport/${id}/send`, data);
  }

  async getFastAccessPassDetails(id: string, baseUrl: string): Promise<IFastAccessPassDetails> {
    ServerClient.defaults.baseURL = baseUrl;
    const res = await ServerClient.get<IFastAccessPassDetails>(`/eiq-passport/${id}/details`);
    return res?.data;
  }

  async getFastAccessForPassAppleWallet(id: string, baseUrl: string): Promise<Blob> {
    ServerClient.defaults.baseURL = baseUrl;
    const response = await ServerClient.get<Blob>(`/eiq-passport/${id}/electronic-wallets/apple`, {
      responseType: 'blob',
    });

    return response.data;
  }
}

export const fastAccessService = new FastAccessService();
export default fastAccessService;
