import React from 'react';
import { Badge } from 'antd';
import { ReactComponent as MessageIcon } from 'assets/icons/eiq-connect/message.svg';
import { useMedia } from 'common/hooks/use-media/use-media.hook';

type NotificationMessageIconProps = {
  isRead: boolean;
};

export const NotificationMessageIcon = ({ isRead }: NotificationMessageIconProps) => {
  const { isMobile } = useMedia();

  const getClassName = () => {
    return isMobile ? 'notification-icon mobile' : 'notification-icon';
  };

  return (
    <div className={getClassName()}>
      {isRead ? (
        <MessageIcon />
      ) : (
        <Badge className="notification-badge avatar-badge" dot>
          <MessageIcon />
        </Badge>
      )}
    </div>
  );
};

export default NotificationMessageIcon;
