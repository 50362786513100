import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { Button, Dropdown } from 'antd';
import { ReactComponent as MessageIcon } from 'assets/icons/eiq-connect/message.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/eiq-connect/check.svg';
import { useTableContext } from '../../../../common/components/table/table-context';
import { INotification } from 'common/services/notification-service/notification.service.types';
import { EllipsisOutlined } from '@ant-design/icons';
import { notificaitonService } from 'common/services/notification-service/notification.service';
import NotificationDetailsModal from './notification-details-modal/notification-details-modal';
import React, { useState } from 'react';
import DateTimeView from 'features/eiq-connect/components/date-time-view/date-time-view';
import { Badge } from 'antd/lib';
import NotificationMessageIcon from './notification-message-icon/notification-message-icon';

export const notificationTableConfig = [
  {
    key: 'avatar',
    title: 'Avatar',
    width: 85,
    minWidth: 85,
    cellRenderer: (cell: ICellRendererProps<any>) => <NotificationMessageIcon isRead={cell.rowData.isRead} />,
  },
  {
    key: 'avatarMobile',
    title: 'Avatar',
    width: 60,
    minWidth: 60,
    style: { alignItems: 'flex-start' },
    cellRenderer: (cell: ICellRendererProps<any>) => {
      const selectedRows = cell.container.props.selectedRows;
      const isSelected = selectedRows.some((row: any) => row.id === cell.rowData.id);
      return (
        <>
          {isSelected ? (
            <div className="check-icon">
              <CheckIcon />
            </div>
          ) : (
            <NotificationMessageIcon isRead={cell.rowData.isRead} />
          )}
        </>
      );
    },
  },
  {
    key: 'subject',
    title: 'Subject',
    dataKey: 'subject',
    width: 300,
    minWidth: 300,
    flexGrow: 5,
    cellRenderer: ({ rowData }: ICellRendererProps<INotification>) => {
      return (
        <div className="eiq-column">
          <div className="message-subject">{rowData.subject}</div>
          <div className={`message-body ${!rowData.isRead ? 'unread' : ''}`} dangerouslySetInnerHTML={{ __html: rowData.message }} />
        </div>
      );
    },
  },
  {
    key: 'createdAt',
    title: 'Date',
    dataKey: 'createdAt',
    width: 160,
    minWidth: 160,
    flexGrow: 2,
    className: 'notification-date',
    cellRenderer: ({ rowData }: ICellRendererProps<INotification>) => (
      <div className="eiq-column message-date">
        <DateTimeView dateTime={rowData.createdAt} className="eiq-column__text" />
      </div>
    ),
  },
  {
    key: 'isRead',
    title: 'Read',
    dataKey: 'isRead',
    width: 70,
    minWidth: 70,
    cellRenderer: ({ rowData }: ICellRendererProps<INotification>) => (
      <div className="eiq-column">
        <div className={`eiq-column__text message-status ${rowData.isRead ? 'read' : ''}`}>{rowData.isRead ? 'Read' : 'Unread'}</div>
      </div>
    ),
  },
  {
    key: 'action',
    title: 'Actions',
    width: 50,
    minWidth: 50,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<INotification>) => {
      const CustomComponent = () => {
        const tableContext = useTableContext();
        const [isDetailsOpened, setIsDetailsOpened] = useState(false);

        const markNotifications = async (id: number, isRead: boolean) => {
          await notificaitonService.markNotifications({ residentNotificationIds: [id], isRead });
          tableContext.refetchData();
          notificaitonService.getPreviewNotificationsData(6);
        };

        const deleteNotifications = async (id: number) => {
          await notificaitonService.deleteNotifications([id]);
          tableContext.refetchData();
          notificaitonService.getPreviewNotificationsData(6);
        };

        const menuProps = {
          items: [
            {
              label: 'Show Details',
              key: 'Details',
            },
            {
              label: 'Mark as Read',
              key: 'Read',
            },
            {
              label: 'Mark as Unread',
              key: 'Unread',
            },
            {
              label: 'Delete',
              key: 'Delete',
            },
          ],
          onClick: (e: any) => {
            if (e.key === 'Details') {
              setIsDetailsOpened(true);
              return;
            }
            if (e.key === 'Read') {
              markNotifications(rowData.id, true);
              return;
            }
            if (e.key === 'Unread') {
              markNotifications(rowData.id, false);
              return;
            }
            if (e.key === 'Delete') {
              deleteNotifications(rowData.id);
              return;
            }
          },
        };

        return (
          <>
            <div style={{ display: 'flex', gap: 10 }}>
              <Dropdown menu={menuProps}>
                <Button size="large" className="round-primary" icon={<EllipsisOutlined />} />
              </Dropdown>
            </div>
            <NotificationDetailsModal
              notification={rowData}
              isOpened={isDetailsOpened}
              onClose={async () => {
                setIsDetailsOpened(false);
                !rowData.isRead && (await markNotifications(rowData.id, true));
              }}
            />
          </>
        );
      };

      return <CustomComponent />;
    },
  },
];
