import { ServerClient } from 'common/utils/http/http-clients';
import {
  GuestDateCheck,
  IAddressOccupant,
  IAdmitGuest,
  IAdmitVisitor,
  ICreateGuest,
  ICreateGuestResponse,
  ITurnbackGuest,
  ITurnbackVisitor,
  IUpdateGuest,
  IUpdateResidentAddressDataSharing,
} from './guest.service.types';
import { GuestValidity } from 'common/enums/guest-validity.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

class GuestService {
  async createGuest(residentId: number, addressId: number, data: ICreateGuest): Promise<ICreateGuestResponse> {
    const res = await ServerClient.post<ICreateGuestResponse>(`/residents/${residentId}/addresses/${addressId}/guests`, data);
    return res?.data;
  }

  async updateGuest(residentId: number, addressId: number, data: IUpdateGuest): Promise<IUpdateGuest> {
    const res = await ServerClient.patch<IUpdateGuest>(`/residents/${residentId}/addresses/${addressId}/guests/${data.id}`, data);
    return res?.data;
  }

  async deleteGuest(residentId: number, addressId: number, guestId: number): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}/addresses/${addressId}/guests/${guestId}`);
    return res?.data;
  }

  async updateResidentAddressDataSharing(
    residentId: number,
    addressId: number,
    data: IUpdateResidentAddressDataSharing,
  ): Promise<IAddressOccupant> {
    const res = await ServerClient.patch<IAddressOccupant>(`/residents/${residentId}/addresses/${addressId}/data-sharing`, data);
    return res?.data;
  }

  async admitGuest(residentId: number, addressId: number, guestId: number, data: IAdmitGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance/guests/${guestId}`, data);
    return res?.data;
  }

  async admitVisitor(residentId: number, addressId: number, data: IAdmitVisitor): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance/visitors`, data);
    return res?.data;
  }

  async admitResident(residentId: number, addressId: number, data: IAdmitGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance`, data);
    return res?.data;
  }

  async turnbackVisitor(residentId: number, addressId: number, data: ITurnbackVisitor): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/turn-back/visitors`, data);
    return res?.data;
  }

  async turnbackGuest(residentId: number, addressId: number, guestId: number, data: ITurnbackGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/turn-back/guests/${guestId}`, data);
    return res?.data;
  }

  isExpired(guest: GuestDateCheck): boolean {
    const currentDate = dayjs().local().startOf('day');

    const isExpired = (data: string): boolean => {
      const visitDate = dayjs.utc(data).local().startOf('day');
      return currentDate.isAfter(visitDate);
    };

    switch (guest.validity) {
      case GuestValidity.OneTime:
      case GuestValidity.Today:
      case GuestValidity.Tomorrow: {
        if (!guest.visitDate) return true;
        return isExpired(guest.visitDate);
      }
      case GuestValidity.DateRange: {
        if (!guest.endDate) return true;
        return isExpired(guest.endDate);
      }
      case GuestValidity.Permanent:
        return false;
      default:
        return true;
    }
  }
}

export const guestService = new GuestService();
export default guestService;
