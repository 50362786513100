import React, { useEffect, useState } from 'react';
import './App.css';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/components/base/private-route/private-route';
import userAuthStore from '../common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from '../common/enums/user-type.enum';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { LoginLayout as WGLoginLayout } from 'features/eiq-connect-login/layout/login-layout';
import { EiqConnectLayout } from '../features/eiq-connect/layout/eiq-connect-layout';
import { Guests } from '../features/eiq-connect/pages/guests/guests';
import { WGLogin } from '../features/eiq-connect-login/pages/login/login';
import { SignUp } from 'features/eiq-connect-login/pages/sign-up/sign-up';
import { Verification } from 'features/eiq-connect-login/pages/verification-code/verification-code';
import { Account } from 'features/eiq-connect/pages/account/account';
import Vehicles from 'features/eiq-connect/pages/vehicles/vehicles';
import { WGTraffic } from 'features/eiq-connect/pages/traffic/traffic';
import fcmService from 'features/eiq-connect/service/fcm-service/fcm.service';
import { Capacitor } from '@capacitor/core';
import featureStore from 'common/stores/features/feature.store';
import ForgotPassword from 'features/eiq-connect-login/pages/forgot-password/forgot-password';
import NewPassword from 'features/eiq-connect-login/pages/forgot-password/components/new-password';
import Pets from 'features/eiq-connect/pages/pets/pets';
import { TermsAndConditions } from 'features/eiq-connect/pages/terms-and-conditions/terms-and-conditions';
import { registerAndroidBackButtonListener } from '../common/helpers/android-back-swipe.helper';
import FastAccessPass from 'features/eiq-connect-fast-access-pass/eiq-connect-fast-access-pass';
import { IS_USER_PREVIOUSLY_WAS_LOGGED } from 'common/stores/user-auth/user-auth.constants';
import { ResidentTypesEnum, getResidentTypes } from 'common/enums/resident-types.enum';
import { OwnershiptSpecificRoute } from 'common/components/base/ownershipt-specific-route/ownershipt-specific-route';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import residentsService from 'common/services/residents-service/residents.service';
import { Citations } from 'features/eiq-connect/pages/citations/citations';
import { FeatureName } from 'common/enums/feature-name.enum';
import AccessCards from 'features/eiq-connect/pages/access-cards/access-cards';
import analyticsService from '../features/eiq-connect/service/analytics-service/analytics.service';
import configurationStore from '../common/stores/configuration/configuration.store';
import { ModalProvider } from 'common/components/modal/notification-confirmation-modal/modal-context';
import { notificaitonService } from 'common/services/notification-service/notification.service';
import { NotificationConfirmationModal } from 'common/components/modal/notification-confirmation-modal/notification-confirmation-modal';
import { Unsubscribe } from 'features/unsubscribe/unsubscribe';
import Notifications from 'features/eiq-connect/pages/notifications/notifications';
import { HelmetProvider } from 'react-helmet-async';

CapacitorUpdater.notifyAppReady();

// let data: BundleInfo | null = null;
// Manual check updates for thw mobile app, [TODO] Add time to the local store and make a request once per day
// CapacitorApp.addListener('appStateChange', async (state: any) => {
//   console.log('appStateChange', state);
//
//   // Run only in APP
//   if (!Capacitor.isNativePlatform()) {
//     return;
//   }
//
//   if (state.isActive) {
//     const vertion = await CapacitorUpdater.current();
//
//     // Do the download during user active app time
//     let latest = null;
//     try {
//       const response = await fetch('https://site.online/update.json');
//       latest = await response.json();
//     } catch (e) {
//       console.log('manual last fail', e);
//     }
//
//     if (vertion.bundle.version !== latest?.version && latest && latest.url) {
//       data = await CapacitorUpdater.download({
//         url: latest.url,
//         version: latest.version,
//       });
//       console.log('download step', latest);
//     } else {
//       console.log('current version is latest', latest);
//     }
//   }
//
//   if (!state.isActive && data) {
//     console.log('set');
//     // Do the switch when user leave app or when you want
//     SplashScreen.show();
//     try {
//       await CapacitorUpdater.set({ id: data.id });
//     } catch (err) {
//       console.log(err);
//       SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
//     }
//   }
// });

const App = ({ props }: any) => {
  useEffect(() => {
    if (Capacitor.isNativePlatform() && userAuthStore.isLogin) {
      fcmService.initPush();
    }
    let notificationsIntervalId: NodeJS.Timer;
    if (userAuthStore.isLogin) {
      analyticsService.initializeAnalytics();
      featureStore.requestFeatures();
      configurationStore.requestConfiguration();

      notificaitonService.getPreviewNotificationsData(6);
      notificationsIntervalId = setInterval(() => {
        notificaitonService.getPreviewNotificationsData(6);
      }, 10000);
    }
    if (Capacitor.getPlatform() === 'android') {
      registerAndroidBackButtonListener();
    }

    if (!eiqConnectStore.residentDetails && userAuthStore.webGuestResidentId) {
      residentsService.getResidentDetails(userAuthStore.webGuestResidentId).then((data) => {
        eiqConnectStore.setResidentDetails(data);
      });
    }

    return () => clearInterval(notificationsIntervalId);
  }, []);

  const getRouteForNotAuthorizedUser = () => {
    const isUserPreviouslyWasLogged = localStorage.getItem(IS_USER_PREVIOUSLY_WAS_LOGGED);
    if (isUserPreviouslyWasLogged) {
      return <Route path="/*" element={<Navigate to="/login" replace={true} />} />;
    } else {
      return <Route path="/*" element={<Navigate to="/sign-up" replace={true} />} />;
    }
  };

  return (
    <HelmetProvider>
      <div className="eiq-connect-app">
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            {!userAuthStore.isLogin && getRouteForNotAuthorizedUser()}

            {userAuthStore.isLogin && userAuthStore.userType === UserTypeEnum.WebGuest && (
              <Route path="/" element={<Navigate to={userAuthStore.prevPath || '/eiq-connect'} replace={true} />} />
            )}

            <Route path="/eiq-passport/:communityId/:fastAccessPassId" element={<FastAccessPass />} />
            <Route path="/eiq-notification/unsubscribe" element={<Unsubscribe />} />

            <Route element={<WGLoginLayout />}>
              <Route path="/login" element={<WGLogin />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/verification" element={<Verification />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/new-password" element={<NewPassword />} />
            </Route>

            <Route path="eiq-connect">
              <Route element={<PrivateRoute userTypes={[UserTypeEnum.WebGuest]} element={<EiqConnectLayout />} navigateTo="/login" />}>
                <Route index element={<Navigate to={'/eiq-connect/guests'} replace={true} />} />
                <Route
                  path="guests/*"
                  element={
                    <ModalProvider
                      checkModalCondition={notificaitonService.checkIfNotificationModalShouldBeShown}
                      modalComponent={NotificationConfirmationModal}>
                      <Guests />
                    </ModalProvider>
                  }
                />
                <Route path="vehicles/*" element={<Vehicles />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="notifications/unread" element={<Notifications isRead={false} />} />
                <Route
                  path="pets/*"
                  element={
                    <OwnershiptSpecificRoute
                      ownerships={getResidentTypes().filter((item) => item !== ResidentTypesEnum.Developer)}
                      element={<Pets />}
                    />
                  }
                />
                <Route path="devices/*" element={<AccessCards />} />
                <Route path="traffic/*" element={<WGTraffic />} />
                {featureStore.IsEnabled(FeatureName.TrafficLogix) && <Route path="citations/*" element={<Citations />} />}
                <Route index element={<Navigate to={'/eiq-connect/account'} replace={true} />} />
                <Route path="account/*" element={<Account />} />
                <Route path="terms-and-condtitions/" element={<TermsAndConditions />} />
              </Route>
            </Route>

            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default observer(App);
