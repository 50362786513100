import './add-to-wallet-btn.scss';
import React from 'react';
import { Button } from 'antd';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { IAddToWalletBtn } from './add-to-wallet-btn.types';

const AddToWalletBtn: React.FC<IAddToWalletBtn> = ({ onClick }) => {
  return (
    <div className="add-to-wallet-container">
      <Button type="primary" onClick={onClick} icon={<SaveIcon />} className="add-to-wallet-btn">
        Add to Wallet
      </Button>
    </div>
  );
};

export default AddToWalletBtn;
