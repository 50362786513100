import { useLocation, Outlet } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import './eiq-connect-layout.scss';
import { ReactComponent as GuestIcon } from 'assets/icons/eiq-connect/guests.svg';
import { ReactComponent as GuestActiveIcon } from 'assets/icons/eiq-connect/guest-active.svg';
import { ReactComponent as VehiclesIcon } from 'assets/icons/eiq-connect/vehicles.svg';
import { ReactComponent as VehiclesActiveIcon } from 'assets/icons/eiq-connect/vehicles-active.svg';
import { ReactComponent as TrafficIcon } from 'assets/icons/eiq-connect/traffic.svg';
import { ReactComponent as TrafficActiveIcon } from 'assets/icons/eiq-connect/traffic-active.svg';
import { ReactComponent as AccountIcon } from 'assets/icons/eiq-connect/account.svg';
import { ReactComponent as AccountActiveIcon } from 'assets/icons/eiq-connect/account-active.svg';
import { ReactComponent as PetsIcon } from 'assets/icons/eiq-connect/pets.svg';
import { ReactComponent as PetsActiveIcon } from 'assets/icons/eiq-connect/pets-active.svg';
import LogoIcon from 'assets/icons/eiq-connect/eiq-connect-logo.png';
import { SidebarLink } from '../components/sidebar-link/sidebar-link';
import { useMedia } from '../../../common/hooks/use-media/use-media.hook';
import eiqConnectStore from '../store/eiq-connect.store';
import residentsService from 'common/services/residents-service/residents.service';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { Device } from '@capacitor/device';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import featureStore from 'common/stores/features/feature.store';
import { getPageTitle } from '../../../common/helpers/page-title.helper';
import analyticsService from '../service/analytics-service/analytics.service';
import configurationStore from '../../../common/stores/configuration/configuration.store';

const { Content, Sider } = Layout;

const isDeviceIPhone = async () => {
  const { platform, model } = await Device.getInfo();

  return platform === 'ios' && (model?.toLowerCase().includes('iphone') || /iPhone/.test(navigator?.userAgent));
};

export const EiqConnectLayout = observer((props: any) => {
  const location = useLocation();
  const { isMobileOrTable, isDesktop, isMobile } = useMedia();
  const isGuestsPath = location.pathname.endsWith('/guests');
  const isVehiclesPath = location.pathname.endsWith('/vehicles');
  const isTrafficPath = location.pathname.endsWith('/traffic');
  const isCitationsPath = location.pathname.endsWith('/citations');
  const isAccountPath = location.pathname.endsWith('/account');
  const isSearchPath = location.pathname.includes('/search');
  const isSharePath = location.pathname.endsWith('/share');
  const isPetsPath = location.pathname.endsWith('/pets');
  const isDevicePath = location.pathname.endsWith('/devices');
  const [isIPhone, setIPhone] = useState(false);
  const [communitySet, setCommunitySet] = useState(false);

  const updateResidentDetails =
    isGuestsPath || isVehiclesPath || isTrafficPath || isAccountPath || isSharePath || isPetsPath || isDevicePath || isCitationsPath;

  useEffect(() => {
    isDeviceIPhone().then((value) => {
      setIPhone(value);
    });
  }, []);

  useEffect(() => {
    if (userAuthStore.webGuestResidentId) {
      const pageTitle = getPageTitle(location.pathname);
      if (!pageTitle) return;
      analyticsService.setCurrentPage(location.pathname, pageTitle);
    }

    const communityName = configurationStore.configuration?.default.communityName;

    if (communityName && communityName.trim() !== '' && !communitySet) {
      analyticsService.setUserCommunity(communityName);
      setCommunitySet(true);
    }
  }, [userAuthStore.webGuestResidentId, configurationStore.configuration, location.pathname, communitySet]);

  useEffect(() => {
    if (userAuthStore.webGuestResidentId && updateResidentDetails) {
      const residentId: number | null = userAuthStore.webGuestResidentId!;
      residentsService
        .getResidentDetails(residentId)
        .then((data) => {
          eiqConnectStore.setResidentDetails(data);
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            userAuthStore.logOut();
          }
          console.error(e);
        });
    }
  }, [userAuthStore.webGuestResidentId, location.pathname]);

  const getVehicleTabName = () => {
    if (!featureStore.isCardAccessEnabled) {
      return 'Vehicles';
    }

    return isMobile ? (
      <span>
        Vehicles/
        <br />
        Devices
      </span>
    ) : (
      'Vehicles/Devices'
    );
  };

  const items = [
    {
      label: <SidebarLink to="/eiq-connect/guests" label="Guests" key="guests" icon={<GuestIcon />} activeIcon={<GuestActiveIcon />} />,
      key: '/eiq-connect/guests',
    },
    isDevicePath
      ? {
          label: (
            <SidebarLink
              to="/eiq-connect/devices"
              label={getVehicleTabName()}
              key="devices"
              icon={<VehiclesIcon />}
              activeIcon={<VehiclesActiveIcon />}
            />
          ),
          key: '/eiq-connect/devices',
        }
      : {
          label: (
            <SidebarLink
              to="/eiq-connect/vehicles"
              label={getVehicleTabName()}
              key="vehicles"
              icon={<VehiclesIcon />}
              activeIcon={<VehiclesActiveIcon />}
            />
          ),
          key: '/eiq-connect/vehicles',
        },
    {
      label: <SidebarLink to="/eiq-connect/pets" label="Pets" key="pets" icon={<PetsIcon />} activeIcon={<PetsActiveIcon />} />,
      key: '/eiq-connect/pets',
    },
    isCitationsPath
      ? {
          label: (
            <SidebarLink
              to="/eiq-connect/citations"
              label="Traffic"
              key="citations"
              icon={<TrafficIcon />}
              activeIcon={<TrafficActiveIcon />}
            />
          ),
          key: '/eiq-connect/citations',
        }
      : {
          label: (
            <SidebarLink
              to="/eiq-connect/traffic"
              label="Traffic"
              key="traffic"
              icon={<TrafficIcon />}
              activeIcon={<TrafficActiveIcon />}
            />
          ),
          key: '/eiq-connect/traffic',
        },
    {
      label: (
        <SidebarLink to="/eiq-connect/account" label="Account" key="account" icon={<AccountIcon />} activeIcon={<AccountActiveIcon />} />
      ),
      key: '/eiq-connect/account',
    },
  ];

  const displayBottomMenu =
    isGuestsPath || isVehiclesPath || isTrafficPath || isAccountPath || isSearchPath || isPetsPath || isDevicePath || isCitationsPath;

  const getMenuItems = useCallback(() => {
    const resident = eiqConnectStore.residentDetails;

    if (!resident) {
      return [];
    }

    if (resident.addresses.every((item) => item.ownershipType === ResidentTypesEnum.Developer)) {
      return items.filter((item) => item.key !== '/eiq-connect/pets');
    } else {
      return items;
    }
  }, [eiqConnectStore.residentDetails, isMobile]);

  return (
    <div className={`eiq-panel ${isIPhone ? 'model-iphone' : ''}`}>
      <Layout>
        {isDesktop && (
          <Sider className="eiq-panel__sider">
            <img className="eiq-panel__logo" src={LogoIcon} alt="logo" />
            <Menu
              defaultSelectedKeys={[location.pathname]}
              selectedKeys={[location.pathname]}
              items={getMenuItems()}
              className="eiq-panel__menu"
            />
          </Sider>
        )}
        <Content className="eiq-panel__content">
          <Outlet />
        </Content>

        {displayBottomMenu && isMobileOrTable && (
          <footer className="eiq-panel__footer">
            <div className="eiq-panel__menu">{getMenuItems().map((item) => item?.label)}</div>

            <div className="eiq-panel__home-indicator"></div>
          </footer>
        )}
      </Layout>
    </div>
  );
});
