import '../eiq-connect-account.scss';
import React, { useEffect, useState } from 'react';
import { Button, QRCode, Select, SelectProps } from 'antd';
import { formatAddressLabel } from 'common/helpers/address.helper';
import fastAccessPassStore from 'common/stores/fast-access-store/fast-access-store';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { ReloadOutlined } from '@ant-design/icons';
import { notification } from 'common/utils/notification';
import { formatVehicleLabel } from 'common/helpers/vehicle.helper';
import { formatEiqPassportCode } from 'common/helpers/eiq-passport.helper';
import CountdownTimer from '../../../../../common/components/сountdown-timer/сountdown-timer';

export const AccountFastAccessBlock = observer(() => {
  const [addressId, setAddressId] = useState<number | null>(null);
  const [vehicleId, setVehicleId] = useState<number | null>(null);
  const [fastAccessPass, setFastAccessPass] = useState<any>(null);
  const [addressOptions, setAddressOptions] = useState<SelectProps['options']>([]);
  const [vehicleOptions, setVehicleOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    const addressOptions = eiqConnectStore.residentDetails?.addresses?.map((item) => ({
      value: item.address.id,
      label: formatAddressLabel(item.address),
    }));

    setAddressOptions(addressOptions || []);

    if (addressOptions) {
      setAddressId(addressOptions[0].value);
    }
  }, [eiqConnectStore?.residentDetails]);

  useEffect(() => {
    if (addressId) {
      const vehicles = eiqConnectStore.residentDetails!.addresses.find((i) => i.address.id === addressId)!.vehicles;

      const vehicleOptions = vehicles.map((item) => ({
        value: item.id,
        label: formatVehicleLabel(item),
      }));

      setVehicleOptions(vehicleOptions || []);
      const firstVehicleId = vehicleOptions[0]?.value ?? null;

      if (!firstVehicleId) {
        fastAccessPassStore.getFastAccessPass(addressId, firstVehicleId).then((response) => {
          setFastAccessPass(response);
        });
      }

      setVehicleId(firstVehicleId);
    }
  }, [addressId]);

  useEffect(() => {
    if (addressId) {
      fastAccessPassStore.getFastAccessPass(addressId, vehicleId).then((response) => {
        setFastAccessPass(response);
      });
    }
  }, [vehicleId]);

  const refreshButtonClickHandle = () => {
    if (!addressId) {
      return;
    }
    fastAccessPassStore.getFastAccessPass(addressId, vehicleId, true).then((response) => {
      setFastAccessPass(response);
      notification.success({ message: 'QR code successfully updated' });
    });
  };

  return (
    <div className="eiq-account-qr-code-wrapper">
      <div className="qr-code-content">
        <div className="qr-code-body">
          <div className="qr-code-border">
            {fastAccessPass && <QRCode value={formatEiqPassportCode(fastAccessPass.id)} size={200} bordered={false} errorLevel="H" />}
          </div>
          <CountdownTimer expires={fastAccessPass?.expires} expiredMessage={'QR code has expired'} />
          <div className="qr-code-text">
            <span className="bold-text">This is your personal code.</span>
            <span>Present this QR code and your photo ID at the gate.</span>
          </div>
        </div>
        <div className="qr-code-form">
          <Select
            options={addressOptions}
            placeholder="Select address"
            className="qr-code-address-select"
            value={addressId}
            onChange={setAddressId}
          />
          <Select
            options={vehicleOptions}
            placeholder="Select vehicle"
            className="qr-code-address-select"
            value={vehicleId}
            onChange={setVehicleId}
          />
          <Button type="primary" icon={<ReloadOutlined />} className="full-space" onClick={refreshButtonClickHandle}>
            Refresh QR code
          </Button>
        </div>
      </div>
    </div>
  );
});
