import { ServerClient } from 'common/utils/http/http-clients';

class UrlService {
  async withTemporaryBaseUrl<T, F extends (...args: any[]) => Promise<T>>(
    newBaseURL: string,
    asyncFunction: F,
    ...args: Parameters<F>
  ): Promise<T> {
    const originalBaseUrl = this.getBaseUrl();
    this.setBaseUrl(newBaseURL);

    try {
      return await asyncFunction(...args);
    } finally {
      this.setBaseUrl(originalBaseUrl);
    }
  }

  setBaseUrl = (baseUrl: string | undefined): void => {
    ServerClient.defaults.baseURL = baseUrl;
  };
  getBaseUrl = (): string | undefined => {
    return ServerClient.defaults.baseURL;
  };
}

export const urlService = new UrlService();
export default urlService;
