import React from 'react';
import { Button, Modal } from 'antd';
import { INotification } from 'common/services/notification-service/notification.service.types';
import DateTimeView from 'features/eiq-connect/components/date-time-view/date-time-view';

type NotificationDetailsModalProps = {
  isOpened: boolean;
  onClose: () => void;
  notification: INotification;
};

export const NotificationDetailsModal = ({ isOpened, onClose, notification }: NotificationDetailsModalProps) => {
  return (
    <Modal title={notification.subject} open={isOpened} onCancel={() => onClose()} footer={false}>
      <div>
        <DateTimeView dateTime={notification.createdAt} />
      </div>
      <br />
      <div dangerouslySetInnerHTML={{ __html: notification.message }} />
      <br />
      <div>
        <Button type="primary" onClick={() => onClose()} block>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationDetailsModal;
